import { registerRoute } from 'workbox-routing';
import {
  NetworkOnly,
  CacheFirst,
  StaleWhileRevalidate,
} from 'workbox-strategies';
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute } from 'workbox-precaching';
import { CacheableResponsePlugin } from 'workbox-cacheable-response';

declare global {
  interface Window {
    __WB_MANIFEST: any[];
  }
}

// eslint-disable-next-line no-restricted-globals,no-underscore-dangle
precacheAndRoute(self.__WB_MANIFEST || []);

registerRoute(/\/cockpit.*$/, new NetworkOnly());

// Images
registerRoute(
  /\.(?:png|jpg|jpeg|svg|webp)$/,
  new CacheFirst({
    cacheName: 'images',
    plugins: [
      new ExpirationPlugin({
        maxEntries: 60,
        maxAgeSeconds: 30 * 24 * 60 * 60, // 30 Days
      }),
    ],
  })
);

// JS and CSS
registerRoute(
  /\.(?:js|css)$/,
  new StaleWhileRevalidate({
    cacheName: 'static-resources',
  })
);

// Webfonts
registerRoute(
  /^https:\/\/use\.typekit\.net\/[^.]*\.css/,
  new StaleWhileRevalidate({
    cacheName: 'typekit-fonts-stylesheets',
  })
);

registerRoute(
  /^https:\/\/use\.typekit\.net\/.*[^.css]$/,
  new CacheFirst({
    cacheName: 'typekit-fonts-webfonts',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({
        maxEntries: 30,
        maxAgeSeconds: 60 * 60 * 24 * 365, // 1 Year
      }),
    ],
  })
);
